// Image Sub Grid Tabs
.sub-grid__img--tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  box-sizing: border-box;
  max-height: 800px;

  .sub-grid__img--item {
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .sub-grid__img--item:nth-child(1) {
    grid-column: 1 / span 2;
  }

  .sub-grid__img--item:nth-child(2) {
    grid-column: 3 / span 2;
  }

  .sub-grid__img--item:nth-child(3) {
    grid-column: 1 / span 4;
  }

}


.tab-section {
  @media only screen and (max-width: $breakPoint1400) {
    .grid__col--3 {
    }
    .grid__col--1 {
      display: none;
    }
    .tabs__content--col {
      grid-column: auto / span 5;
    }
  }

  @media only screen and (max-width: $breakPoint1300) {
    .grid__col--3 {
      grid-column: auto / span 4;
    }
    .tabs__content--col {
      grid-column: auto / span 8;
    }

    .tabs__img--col {
      grid-column: auto / span 12;


      .sub-grid__img--item:nth-child(1) {
        grid-column: auto / span 1;
      }

      .sub-grid__img--item:nth-child(2) {
        grid-column: auto / span 1;
      }

      .sub-grid__img--item:nth-child(3) {
        grid-column: auto / span 2;
      }

    }
  }

  @media only screen and (max-width: $breakPoint850) {
    .tabs__list--col {
      display: none;
    }


    .tabs__content--col {
      grid-column: auto / span 12;


      #tabs__content > div {
        display: block;

        h3, p {
          display: none;
        }
      }

      #tabs__content > div.active {
        display: block;

        h3, p {
          display: block;
        }
      }


      h2 {
        list-style: none;
        padding: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        box-shadow: $box-shadow;
        border: 1px solid $color-secondary;
        background-color: $color-white;

        font-weight: 700;

        transition: border .2s ease-in-out, background-color .2s ease-in-out;


        position: relative;

        &:after {
          position: absolute;
          right: 25px;
          content: url("../img/icons/arrow.svg");

          rotate: 90deg;
          transform-origin: 47% 42%;

          transition: rotate 0.15s ease-out;
        }


        &.active {
          border: 1px solid $color-call-to-action;
          background-color: $color-background-gradient;

          &:after {
            rotate: 270deg;
          }
        }
      }

      div.active {
        h2 {
          border: 1px solid $color-call-to-action;
          background-color: $color-background-gradient;

          &:after {
            rotate: 270deg;
            transform-origin: 47% 42%;
          }
        }
      }
    }
  }
}