.legal-page {
  margin-top: 155px;

  @media only screen and (max-width: $breakPoint1600) {
    margin-top: 125px;
  }

  @media only screen and (max-width: $breakPoint1400) {
    margin-top: 105px;
  }

}