// =========
/// Colors
// =========

$color-primary: #F6A900;
$color-secondary: #FAF9F8;
$color-white: #ffffff;
$color-black: #000000;
$color-call-to-action: #F6A900;
$color-background: #FAF9F8;
$color-background-gradient: #E2EAE6;
$color-background-opacity: rgba(196, 177, 161, 0.2);

$color-silver: #E0E1E2;

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);


// media queries - breakpoints
$breakPoint1800: 1800px;
$breakPoint1700: 1700px;
$breakPoint1600: 1600px;
$breakPoint1500: 1500px;
$breakPoint1400: 1400px;
$breakPoint1300: 1300px;
$breakPoint1201: 1201px;
$breakPoint1200: 1200px;
$breakPoint1100: 1100px;
$breakPoint1000: 1000px;
$breakPoint950: 950px;
$breakPoint850: 850px;
$breakPoint780: 780px;
$breakPoint680: 680px;
$breakPoint600: 600px;
$breakPoint540: 540px;
$breakPoint480: 480px;