body {
  background-color: $color-black;
  overflow-x: hidden;
}

main {
  min-height: 100vh;
}

header, main, footer {
  background-color: gray;

  background-image: url("../img/background/pizzeria_sonne_bg.jpg");
  background-repeat: no-repeat;

  background-size: cover;

  background-position: center;


  max-width: 1920px;
  margin: 0 auto;
}

.grid, .grid__img, footer {
  max-width: 1920px;
  margin: 0 auto;
}

.content__img--full-height {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (max-width: $breakPoint1200) {
    max-height: 600px;
  }
}

.content__img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

  }
}

.section__spacing {
  padding-top: 60px;
  padding-bottom: 60px;

  @media only screen and (max-width: $breakPoint1200) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media only screen and (max-width: $breakPoint850) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: $breakPoint600) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media only screen and (max-width: $breakPoint480) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.section__spacing--small {
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: $breakPoint1200) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media only screen and (max-width: $breakPoint850) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: $breakPoint600) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media only screen and (max-width: $breakPoint480) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}



.section__offset-top {
  margin-top: 60px;

  @media only screen and (max-width: $breakPoint1200) {
    margin-top: 40px;
  }

  @media only screen and (max-width: $breakPoint850) {
    margin-top: 0;
  }
}



.content__relative {
  position: relative;
}

.content__spacing--left {
  padding-left: 60px;

  @media only screen and (max-width: $breakPoint1200) {
    padding-left: 0;
  }
}

.content__center {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 40px 0;

}

.content__background {
  background-color: $color-secondary;
}

.content__background--gradient {
  background: linear-gradient($color-background, $color-background-gradient);
}



