#tabs__list,
#faq__list {

  display: flex;
  flex-direction: column;

  li {
    list-style: none;
    padding: 20px 50px 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: $box-shadow;
    border: 1px solid $color-secondary;
    background-color: $color-white;

    font-weight: 700;

    transition: border .2s ease-in-out, background-color .2s ease-in-out;

    position: relative;
    
    &:after {
      position: absolute;
      right: 25px;
      content: url("../img/icons/arrow.svg");

      transition: rotate 0.15s ease-out;
    }


    &.active {
      border: 1px solid $color-call-to-action;
      background-color: $color-background-gradient;

      &:after {
        rotate: 180deg;
        transform-origin: 47% 42%;
      }
    }
  }
}

#tabs__content > div,
#faq__content > div {
  display: none;
}

#tabs__content > div.active,
#faq__content > div.active {
  display: block;
}