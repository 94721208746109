.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0;
  box-sizing: border-box;
  height: 100%;

  padding: 0 60px;

  @media only screen and (max-width: $breakPoint1200) {
    grid-column-gap: 30px;
    padding: 0 40px;
  }

  @media only screen and (max-width: $breakPoint850) {
    grid-column-gap: 20px;
    padding: 0 30px;
  }
  @media only screen and (max-width: $breakPoint600) {
    padding: 0 20px;
  }

}

$columnWidths: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $column in $columnWidths {
  .grid__col--#{$column} {
    grid-column: auto / span $column;

    @media only screen and (max-width: $breakPoint850) {
      grid-column: 1 / span 12;
    }
  }
}


// Image Sub Grid
.sub-grid__img {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  box-sizing: border-box;
  height: 100%;
  max-height: 800px;

  .sub-grid__img--item {
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .sub-grid__img--item:nth-child(1) {
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
  }

  .sub-grid__img--item:nth-child(2) {
    grid-column: 4 / span 4;
    grid-row: 1 / span 3;
  }

  .sub-grid__img--item:nth-child(3) {
    grid-column: 1 / span 4;
    grid-row: 4 / span 5;
  }

  .sub-grid__img--item:nth-child(4) {
    grid-column: 5 / span 3;
    grid-row: 4 / span 2;
  }

  .sub-grid__img--item:nth-child(5) {
    grid-column: 5 / span 3;
    grid-row: 6 / span 3;
  }

}


// Flex Cols

.flex-2col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  .flex-col__50 {
    width: 48%;

  }
}

@media only screen and (max-width: $breakPoint600) {
  .flex-2col {
    flex-direction: column;

    .flex-col__50 {
      width: 100%;
      padding-bottom: 20px;
    }
  }
}

