.grid.intro {
  .grid__col--4 {

    @media only screen and (max-width: $breakPoint1400) {
      grid-column: 1 / span 5;
    }

    @media only screen and (max-width: $breakPoint1200) {
      grid-column: 1 / span 6;
    }

    @media only screen and (max-width: $breakPoint1000) {
      grid-column: 1 / span 7;
    }

    @media only screen and (max-width: $breakPoint850) {
      grid-column: 1 / span 8;
    }

    @media only screen and (max-width: $breakPoint600) {
      grid-column: 1 / span 12;
    }

  }
}
