.baustelle-logo {
  width: 200px;
  height: auto;

  margin-bottom: 50px;
}

.baustelle-intro {
  margin-bottom: 100px;

  max-width: 500px;
}


@media only screen and (max-width: $breakPoint850) {
  .baustelle-logo {
    margin-bottom: 30px;
  }

  .baustelle-intro {
    margin-bottom: 50px;
  }

  .baustelle-2col {
    margin-bottom: 30px
  }

}




