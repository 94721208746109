.grid.praxis {

  @media only screen and (max-width: $breakPoint1400) {
    .grid__col--1 {
      display: none;

    }
    .grid__col--4 {
      grid-column: auto / span 5;

    }
  }

  @media only screen and (max-width: $breakPoint850) {
    .grid__col--4 {
      grid-column: auto / span 8;

    }
  }

  @media only screen and (max-width: $breakPoint780) {
    .grid__col--4 {
      grid-column: auto / span 10;

    }
  }

  @media only screen and (max-width: $breakPoint600) {
    .grid__col--4 {
      grid-column: auto / span 12;

    }
  }
}
