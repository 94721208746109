img {
  max-width: 100%;
  max-height: 100%;
}


//Background Images
.background__logo--right {
position: relative;

  &:after {
    position: absolute;

    content: url("../img/logo/logo-beller-background-logo.svg");

    bottom: -5px;
    right: 20px;

    z-index: 0;
  }
}

.background__logo--center {
  position: relative;

  &:after {
    position: absolute;

    content: url("../img/logo/logo-beller-background-logo.svg");

    bottom: -5px;
    right: 20%;

    z-index: 0;
  }
}

.background__logo--left {
  position: relative;

  &:after {
    position: absolute;

    content: url("../img/logo/logo-beller-background-logo.svg");

    top: 100px;
    left: 80px;

    z-index: 0;
  }
}

.background__logo-big--center {
  position: relative;

  &:after {
    position: absolute;

    content: url("../img/logo/logo-beller-background-logo-big.svg");

    bottom: 53px;
    right: 25%;

    z-index: 0;

    @media only screen and (max-width: $breakPoint850) {
      right: auto;
      left: 0;
    }
  }
}