/* sen-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Sen';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/sen-v9-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* sen-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Sen';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/sen-v9-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* sen-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Sen';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/sen-v9-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}



/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v26-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/montserrat-v26-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/montserrat-v26-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}







html {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 10px;

  color: $color-silver;

  * {
    z-index: 1;
  }
}

body {
  font-size: 1.6em;
  line-height: 1.4;
}


* {
  &::selection {
    color: $color-white;
    background: $color-call-to-action; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    color: $color-white;
    background: $color-call-to-action; /* Gecko Browsers */
  }
}

strong {
  font-weight: 900;
}


h1 {
  font-size: 6rem;
  line-height: 1.14;
  font-weight: 700;
  margin: 0;

  text-transform: uppercase;


  @media only screen and (max-width: $breakPoint1400) {
    font-size: 4.5rem;
  }

  @media only screen and (max-width: $breakPoint1200) {
    font-size: 3.5rem;
  }

  @media only screen and (max-width: $breakPoint850) {
    font-size: 3rem;
  }

}


h2 {
  font-size: 4rem;
  margin: 0 0 0.5em 0;
  font-weight: normal;

  line-height: 1.14;

  @media only screen and (max-width: $breakPoint1400) {
    font-size: 3.2rem;
  }

  @media only screen and (max-width: $breakPoint1200) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: $breakPoint850) {
    font-size: 2rem;
  }
}


h3 {
  font-size: 2.5rem;
  margin: 0 0 0.5em 0;
  font-weight: normal;

  line-height: 1.14;

  @media only screen and (max-width: $breakPoint1200) {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.25em;
  margin: 0 0 1em 0;

  line-height: 1.14;

  text-transform: uppercase;
}

h5 {
  font-size: 1.125em;
  margin: 0 0 1em 0;

  line-height: 1.14;
}

h6 {
  font-size: 1em;
  margin: 0 0 1em 0;

  line-height: 1.14;
}


p {
  margin-bottom: 20px;
}




// Links
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: $color-call-to-action;
    text-decoration: underline;
  }
}


// lists
main {
  ul {
    list-style: disc inside;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1em;
    }
  }
}


// Buttons
.button-primary {
  text-decoration: none;
  font-weight: 700;

  background-color: $color-call-to-action;
  border: 2px solid $color-call-to-action;
  color: $color-white;
  width: 250px;
  height: 50px;
  margin-top: 40px;

  border-radius: 50px;

  display: flex;
  align-items: center;
  transition: color .2s ease-in-out, background-color .2s ease-in-out;
  justify-content: center;

  &:hover {
    background-color: $color-secondary;
    border: 2px solid $color-call-to-action;
    color: $color-black;
    text-decoration: none;
  }
}