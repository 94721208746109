.page-footer {
  .page-footer__col {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakPoint1700) {
    .page-footer__info {
      grid-column: 1 / span 3;
    }
    .page-footer__address {
      grid-column: auto / span 2;
    }

    .page-footer__contact {
      grid-column: auto / span 3;
    }
    .page-footer__opening-times {
      grid-column: auto / span 2;
    }
    .page-footer__links {
      grid-column: auto / span 2;
    }
  }

  @media only screen and (max-width: $breakPoint1500) {

    //.page-footer__info {
    //  grid-column: 1 / span 3;
    //}
    //.page-footer__address {
    //  grid-column: 4 / span 3;
    //}
    //.page-footer__contact {
    //  grid-column: 7 / span 3;
    //}
    .page-footer__opening-times {
      grid-column: auto / span 3;
    }
    .page-footer__links {
      grid-column: auto / span 12;
    }
  }

  @media only screen and (max-width: $breakPoint1400) {

    .page-footer__info {
      grid-column: auto / span 4;
    }
    .page-footer__address {
      grid-column: auto / span 2;
    }
    .page-footer__contact {
      grid-column: auto / span 3;
    }
    .page-footer__opening-times {
      grid-column: auto / span 3;
    }
  }

  @media only screen and (max-width: $breakPoint1300) {

    .page-footer__info {
      grid-column: auto / span 12;
    }
    .page-footer__address {
      grid-column: auto / span 3;
    }
    .page-footer__contact {
      grid-column: auto / span 3;
    }
    .page-footer__opening-times {
      grid-column: auto / span 3;
    }
    .page-footer__links {
      grid-column: auto / span 3;
    }
  }

  @media only screen and (max-width: $breakPoint1300) {

    .page-footer__info {
      grid-column: auto / span 12;
    }
    .page-footer__address {
      grid-column: auto / span 3;
    }
    .page-footer__contact {
      grid-column: auto / span 3;
    }
    .page-footer__opening-times {
      grid-column: auto / span 3;
    }
    .page-footer__links {
      grid-column: auto / span 3;
    }
  }

  @media only screen and (max-width: $breakPoint1100) {

    .page-footer__info {
      grid-column: auto / span 4;
    }
    .page-footer__address {
      grid-column: auto / span 3;
    }
    .page-footer__contact {
      grid-column: auto / span 4;
    }
    .page-footer__opening-times {
      grid-column: auto / span 4;
    }
    .page-footer__links {
      grid-column: auto / span 4;
    }
  }

  @media only screen and (max-width: $breakPoint1000) {

    .page-footer__info {
      grid-column: auto / span 12;
    }
    .page-footer__address {
      grid-column: auto / span 4;
    }
    .page-footer__contact {
      grid-column: auto / span 4;
    }
    .page-footer__opening-times {
      grid-column: auto / span 4;
    }
    .page-footer__links {
      grid-column: auto / span 4;
    }
  }

  @media only screen and (max-width: $breakPoint1000) {

    .page-footer__info,
    .page-footer__address,
    .page-footer__contact,
    .page-footer__opening-times,
    .page-footer__links {
      grid-column: auto / span 12;
    }
  }


}

