.testimonial {
  display: flex;
  flex-direction: row;
  gap: 30px;

  .testimonial__img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .testimonial__content {

    .testimonial__text {
      font-size: 2.2rem;
    }

  }
}

@media only screen and (max-width: $breakPoint1600) {
  .testimonial {
    gap: 15px;
    .testimonial__img {
      width: 95px;
      height: 95px;
    }

    .testimonial__content {
      .testimonial__text {
        font-size: 2rem;
      }

    }
  }
}

@media only screen and (max-width: $breakPoint1400) {
  .testimonial {
    flex-direction: column;
    gap: 20px;

    .testimonial__img {
      width: 120px;
      height: 120px;
    }


  }
}

@media only screen and (max-width: $breakPoint850) {
  .testimonial {
    flex-direction: row;
    gap: 20px;

    margin-bottom: 40px;

    .testimonial__img {
      width: 120px;
      height: 120px;
    }


  }
}

@media only screen and (max-width: $breakPoint540) {
  .testimonial {
    flex-direction: column;
  }

  .testimonial__content {
    .testimonial__text {
      font-size: 1.8rem;
    }

  }
}
