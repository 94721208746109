.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: $color-secondary;
  transition: all 0.3s ease-in-out;

  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;

  padding: 10px 40px 20px 40px;


  .navigation__inner {
    display: flex;
    justify-content: space-between;
  }

  .navigation__logo {
    width: 320px;

    img {
      width: 100%;
      height: 100%;
    }

    transition: width 0.3s ease-in-out;
  }

  .navigation__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    align-self: end;

    margin-left: 140px;


    .navigation__list--item {
      margin-left: 40px;
      font-size: 1.8rem;

      a {
        text-decoration: none;
      }

      &:first-child {
        margin-left: 0;
      }

      a.button-primary {
        margin: 0;
        padding: 0;

        width: 130px;

        margin-left: 20px;
      }
    }
  }

  .navigation__meta {
    a {
      margin-left: 40px;
    }
  }
}


@media only screen and (max-width: $breakPoint1800) {
  .navigation {
    .navigation__list {
      margin-left: 40px;

      .navigation__list--item {
        margin-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: $breakPoint1600) {
  .navigation {

    .navigation__logo {
      width: 240px;

    }

    .navigation__list {
      margin-left: 40px;

      .navigation__list--item {
        margin-left: 20px;
      }
    }

    .navigation__meta {
      a {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakPoint1500) {
  .navigation {

    .navigation__meta {
      display: flex;
      flex-direction: column;

      a {
        margin-left: 0;
        margin-bottom: 5px;
      }
    }

  }
}

@media only screen and (max-width: $breakPoint1400) {
  .navigation {
    .navigation__logo {
      width: 180px;

    }

    .navigation__list {
      margin-left: 20px;

      .navigation__list--item {
        margin-left: 20px;
      }
    }
  }
}


.fixed-nav-active {
  box-shadow: $box-shadow;
  padding: 10px 40px;

  .navigation__logo {
    width: 140px;
  }
}


.navigation {
  .menu,
  .close {
    display: none;

    .st6 {
      fill: $color-call-to-action;

      transition: fill 0.2s ease-out;
    }

    &:hover {
      .st6 {
        fill: $color-background-gradient;
      }
    }
  }
}


// ----------------------------
// mobil Navigation -- START --
// ----------------------------
@media only screen and (max-width: $breakPoint1300) {
  .navigation {

    .navigation__meta {
      display: none;
    }



    .menu {
      display: block;
      width: 40px;
      height: 40px;
      transition: opacity 0.7s ease-out;
      padding: 1px;
      z-index: 50;

      margin-top: 10px;

      &:hover {
        cursor: pointer;
      }

    }

    .close {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.7s ease-out;
    }

  }

  .navigation__link {
    display: block;
    width: 180px;

    background-color: $color-background;
    color: $color-black;

    padding: 15px 15px 15px 60px;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;

    &:hover {
      color: $color-white;
      background-color: $color-call-to-action;
    }

  }


  .navigation {
    .navigation__list {
      text-transform: uppercase;
      right: -300px;
      width: 250px;

      position: fixed;
      top: 0;
      height: 100vh;
      justify-content: center;


      z-index: 2;
      transition: right .6s ease-in-out;

      display: flex;
      flex-direction: column;


      .navigation__list--item {
        margin: 0;
        box-shadow: $box-shadow;
      }
    }
  }

  .nav-active {
    .navigation__list {
      right: 0;
    }

    .open {
      display: none;
      opacity: 0;
    }

    .close {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
}




